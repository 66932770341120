import { ApiPath } from '~/enum/apiPath'
import http from '~/http-common'
import type { UnreadRes } from '~~/types/unreadRes'
class GetUnreadMessagesService {
  get = async (): Promise<UnreadRes> => {
    const path = ApiPath.GET_UNREAD_MESSAGES_GET
    const headers = {
      mode: 'cors',
      credentials: 'include',
      csrfToken: sessionStorage.getItem('token')!,
    }
    return http.get(path, { headers: headers })
  }
}
export default new GetUnreadMessagesService()
