import GetUnreadMessagesService from '~/services/GetUnreadMessagesService'
import type { UnreadRes } from '~~/types/unreadRes'
import { defineStore } from 'pinia'
import { inject, ref } from 'vue'
import { updateIsBackgroundLoadingKey } from '~/const/updateIsBackgroundLoadingKey'
import * as Sentry from '@sentry/vue'

const UNREAD_COUNT_MANAGER_STORE_KEY = 'UnreadCountManagerStore'

export const useUnreadCountManagerStore = defineStore(UNREAD_COUNT_MANAGER_STORE_KEY, () => {

  /**
   *  未読連絡件数
   */
  const unreadMessageCount = ref(0)

  const resetUnreadMessageCount = () => {
    unreadMessageCount.value =  0
  }

  /**
   * APIから未読連絡件数を取得する処理
   */
  const loadUnreadMessageCount = () => {
    const backgroundLoading = inject(updateIsBackgroundLoadingKey)!
    backgroundLoading?.('loadUnreadMessageCount', true)
    GetUnreadMessagesService.get().then((response: UnreadRes) => {
      unreadMessageCount.value = response.data.unreadCount
    })
    .catch((error) => {
      // Sentryにエラーを通知
      Sentry.captureException(error)
    }).finally(() => {
      backgroundLoading?.('loadUnreadMessageCount', false)
    })
  }

  return {
    unreadMessageCount,
    resetUnreadMessageCount,
    loadUnreadMessageCount,
  }
}, {
  persist: {
    storage: sessionStorage,
  }
})
